<template>
  <div>
     <canvas height="400"></canvas>
   </div>
</template>

<script>
import Chart  from "chart.js/dist/Chart.min.js";
export default {

data: () => ({
  chart: {}

  }),

mounted: function(){
 let {chartType,graphData,chartOptions} = this;
  this.chartConstructor(chartType, graphData, chartOptions);

},


beforeCreate: function() {
  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 20;
};

},


watch: {
 graphData: {
   handler(val){
     if(val){
       //this.chart.update();
        let {chartType,graphData,chartOptions} = this;
        this.chartConstructor(chartType, graphData, chartOptions);
     }
   }
 }
},

// updated: function() {
//   this.chart.update();
//   console.log('updated')
// },



props:{
  chartType:String,
  chartData:Object,
  chartOptions:Object,
  apiUsageChart: Boolean,
  
},

computed: {

  graphData : function(){
   if(this.apiUsageChart){
    return  this.$store.state.userdata.graphData;}
    else{
      return this.chartData
    }
    
  }

},

methods: {
    chartConstructor(chartType, chartData, chartOptions) {
    const chartElement = document.querySelector("canvas");
    this.chart = new Chart(chartElement, {
    type: chartType,
    data: chartData,
    options: chartOptions,
  });
},
}

}
</script>

<style>

</style>